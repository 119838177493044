'use strict';

jQuery.noConflict();

jQuery( document ).ready( function( $ ) {

	var siteInit = {

		DOMready: function() {

			if ( $('.js-slider--primary').length > 0 ) {
				siteInit.slider();
			}
			if ( $('.js-carousel').length > 0 ) {
				siteInit.carousel();
			}

			if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
				siteInit.modal();
			}

			if ( $('.js-navicon').length > 0 ) {
				siteInit.mobileMenu();
			}

			if ( $('#projects').length > 0 ) {
				siteInit.filterProjects();
			}

		},

		// Filtri della pagina progetti
		filterProjects: function() {
			$('#filter').on('click', '.js-filter', function() {
				var active = $(this).data('filter');
				$('.js-filter').each(function(){
					if ( $(this).hasClass('filter__item--active') ) {
						$(this).removeClass('filter__item--active');
					}
				});

				$(this).addClass('filter__item--active');

				$('#projects').find('.js-filter-target').each(function() {

					if ( $(this).data('location') !== active ) {
						$(this).find('.card').removeClass('card--borded').parent().addClass('hide');
					} else {
						$(this).find('.card').addClass('card--borded').parent().removeClass('hide');
					}

					if ( active === 'all' ) {
						console.log($(this));
						$(this).find('.card').addClass('card--borded').parent().removeClass('hide');
					}

				});
			});
		},

		// Slider
		slider: function() {

			$(".js-slider--primary").slick({
				dots: true,
				speed: 500
			});

		},
		carousel: function() {

			$(".js-carousel").slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: true
			});

		},

		// Modal
		modal: function() {

			$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
				transition: 'elastic',
				speed: 400,
				opacity: 0.8,
				slideshow: true,
				slideshowSpeed: 4000,
				itemsnitialWidth: 50,
				initialHeight: 50,
				maxWidth: '90%',
				maxHeight: '90%',
			});

		},

		// Mobile menu
		mobileMenu: function() {

			$('body').on( 'click', '.js-navicon', function() {
				$(this).toggleClass('navicon--active');
				$('.navigation').toggleClass('navigation--open');
			});

		}

	};

	siteInit.DOMready();

});


document.addEventListener( 'wpcf7mailsent', function( event ) {
    if ( event.detail.contactFormId == '284' ) {
    	ga('send', 'pageview', 'grazie.html');
    } else if (  event.detail.contactFormId == '1556'  ) {
    	ga('send', 'pageview', 'eng/thanks.html');
    } else if (  event.detail.contactFormId == '1267'  ) {
    	ga('send', 'pageview', 'de/thanks.html');
    } else if (  event.detail.contactFormId == '1394'  ) {
    	ga('send', 'pageview', 'no/thanks.html');
    } else if (  event.detail.contactFormId == '1443'  ) {
    	ga('send', 'pageview', 'pt/thanks.html');
    }

}, false );